import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Tooltip, ListItemText, Link } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// auth
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
//
import Iconify from '../../iconify';
import { StyledItem, StyledIcon } from './styles';
// ----------------------------------------------------------------------
const NavItem = forwardRef(({ item, depth, open, active, isExternalLink, ...other }, ref) => {
    const { translate } = useLocales();
    const { title, path, icon, info, children, disabled, caption, roles } = item;
    const subItem = depth !== 1;
    const renderContent = (_jsxs(StyledItem, { ref: ref, open: open, depth: depth, active: active, disabled: disabled, ...other, children: [icon && _jsx(StyledIcon, { children: icon }), _jsx(ListItemText, { primary: `${translate(title)}`, primaryTypographyProps: {
                    noWrap: true,
                    component: 'span',
                    variant: active ? 'subtitle2' : 'body2',
                } }), info && (_jsx(Box, { component: "span", sx: { ml: 1, lineHeight: 0 }, children: info })), caption && (_jsx(Tooltip, { title: `${translate(caption)}`, arrow: true, children: _jsx(Box, { component: "span", sx: { ml: 0.5, lineHeight: 0 }, children: _jsx(Iconify, { icon: "eva:info-outline", width: 16 }) }) })), !!children && (_jsx(Iconify, { icon: subItem ? 'eva:chevron-right-fill' : 'eva:chevron-down-fill', width: 16, sx: { ml: 0.5, flexShrink: 0 } }))] }));
    const renderItem = () => {
        // ExternalLink
        if (isExternalLink)
            return (_jsx(Link, { href: path, target: "_blank", rel: "noopener", underline: "none", children: renderContent }));
        // Default
        return (_jsx(Link, { component: RouterLink, to: path, underline: "none", children: renderContent }));
    };
    return _jsxs(RoleBasedGuard, { roles: roles, children: [" ", renderItem(), " "] });
});
export default NavItem;
